<template>
    <div>

        <cHeaderBuyer activeNav = "atc"></cHeaderBuyer>
        <div class = "body">
              <div class = "page-wrap">
                    <cAtcMenu></cAtcMenu>
                     <div class = "col-right">
                        <div class = "container">
                            <div class = "info-wrap">
                                <div class = "head-info-wrap">
                                    <div class = "head"><img
                                        src = "https://scce-cos-obs.obs.cn-south-1.myhuaweicloud.com:443/9ee9e596e0ba45ff851493175d199666.png">
                                    </div>
                                    <div class = "name">{{ userInfo.nickname }}</div>
                                </div>
                                <ul class = "user-info-wrap">
                                    <li>用户名：{{ userInfo.username }} | 手机号：{{ userInfo.mobile }}</li>
                                    <li>性别：{{ userInfo.gender_desc }} | 生日：{{ userInfo.birthday }}</li>
                                    <li>真实姓名：{{ userInfo.realname || '--' }} | 身份证号码：{{ userInfo.id_card_no || '--' }}</li>
                                </ul>
                                <div class = "mdy-btn">
                                    <a-button @click = "() => {$router.push('/atc/mdyUserInfo')}">修改</a-button>
                                </div>
                            </div>
                            <div class = "hr"></div>
                            <div class = "table-wrap">
                                <a-table :columns = "columns" :data-source = "userData" :pagination = "false" :showHeader = "false"
                                >
                                    <div slot = "status" slot-scope = "text, record">
                                        <a-icon v-if = "record.status" type = "check-circle" :style = "{color:'#17c031'}" /><a-icon
                                        v-else type = "question-circle" /><span class = "ml12">{{ record.statusDesc }}</span>
                                    </div>
                                    <div slot = "action" slot-scope = "text, record" style = "padding: 32px 16px;">
                                        <a-button v-if = "record.canUpdate" @click = "record.key=='isUpdateUserName'? showUpdateUsername=true : jump(record.path)">修改</a-button>
                                        <span v-else>-</span>
                                    </div>
                                </a-table>

                            </div>
                        </div>
                    </div>
              </div>
        </div>

        <a-modal v-model="showUpdateUsername" title="修改用户名"  @ok="updateUsername" cancelText="取消" okText="确定">
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
              <a-form-item label="用户名(旧)">
                  <div>{{userInfo.username}}</div>
              </a-form-item>
              <a-form-item label="用户名(新)" required>
                  <div slot = "extra">3-30个字符，支持中英文、数字、符号"_"和"-"，只能修改一次</div>
                  <a-input v-model="new_username" placeholder="请输入新用户名"></a-input>
              </a-form-item>
                <a-form-item label="验证码" required>
                    <div slot="extra">为了账户安全，需您的手机验证（{{userInfo.mobile}}）</div>
                    <a-input v-model="verify_code" placeholder="请输入验证码" size="large">
                    <div slot="suffix" class="getVerifyCode" :class="countDown<60 ? 'disableColor' : ''" @click="getVerifyCode(5)">
                    {{getVerifyCodeText}}
                    </div>
                    </a-input>
                </a-form-item>


          </a-form>
        </a-modal>

    </div>


</template>

<script>
    import service from "../../../utils/request";
    import cHeaderBuyer from "@/component/header-buyer";
    import cAtcMenu from "@/component/atc-menu";

    export default {
        name: "account",
        components: {
            cHeaderBuyer,
            cAtcMenu
        },
        data() {
            return {
                showUpdateUsername:false,
                getVerifyCodeText:'获取验证码',
                countDown: 60,
                verify_code:'',
                new_username:'',
                userInfo: {},
                columns: [
                    {title: 'label', dataIndex: 'label'},
                    {title: 'content', dataIndex: 'content'},
                    {title: 'statusDesc', dataIndex: 'statusDesc', scopedSlots: {customRender: 'status'}},
                    {title: 'action', dataIndex: 'action', scopedSlots: {customRender: 'action'}},
                ],
                userData: [
                    {
                        key: 'phone',
                        label: '手机号码',
                        content: '',
                        status: 1,
                        statusDesc: '已绑定',
                        canUpdate: true,
                        path: '/atc/my-phone',
                    },
                    {
                        key: 'isPwd',
                        label: '登录密码',
                        content: '定期更换密码有助于账号安全',
                        status: 1,
                        statusDesc: '已设置',
                        canUpdate: true,
                        path: '/atc/myd-pwd',
                    },
                    {
                        key: 'isCert',
                        label: '实名认证',
                        content: '根据业务合规性要求收集用户实名信息',
                        status: 0,
                        statusDesc: '未实名',
                        canUpdate: false,
                    },
                    {
                        key: 'isUpdateUserName',
                        label: '用户名',
                        content: '用户身份识别码，仅支持修改一次',
                        status: 0,
                        statusDesc: '未修改',
                        canUpdate: true,

                    },
                ]
            }
        },
        created() {
            this.getAccount();
        },
        methods: {
            getAccount() {
                service.post(service.uri.user.getUserInfo).then((res) => {
                    this.userInfo = res.data
                    this.userData.forEach((item, i) => {
                        if (item.key == 'phone') {
                            this.userData[i].content = this.userInfo.mobile;
                        }
                        if(this.userInfo.update_username && item.key=='isUpdateUserName'){
                            this.userData[i].status = 1;
                            this.userData[i].statusDesc = '已修改';
                            this.userData[i].canUpdate = false;
                        }
                    })
                });
            },
            jump(path) {
                this.$router.push(path);
            },
            getVerifyCode( type){
                if(this.countDown < 60) return false;
                service.post(service.uri.user.getVerifyCode, {phone: this.userInfo.phone, type: type}).then(() => {

                    this.timer = setInterval(() => {
                        this.countDown--;
                        if(this.countDown <= 0){
                            this.getVerifyCodeText = '获取验证码';
                            this.countDown = 60;
                            clearInterval(this.timer);
                            return ;
                        }
                        this.getVerifyCodeText = '请等待'+this.countDown+'S';
                    }, 1000);
                });
            },
            updateUsername(){
                service.post(service.uri.user.updateUsername, {new_username: this.new_username, verify_code: this.verify_code}).then(res => {
                    if(res.code == 200){
                        this.showUpdateUsername = false;
                        this.userData.forEach((item, i) => {
                            if (item.key == 'isUpdateUserName') {
                                this.userData[i].status = 1;
                                this.userData[i].statusDesc = '已修改';
                                this.userData[i].canUpdate = false;
                            }
                        })
                    }
                });
            }
        }
    }
</script>

<style lang = "less" scoped>
.body {
    background: #fbfbfb;
}

.page-wrap {
    width: 1200px;
    margin: 0 auto;
}
a {
    color: #de0000;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
}

dt {
    font-weight: 500;
}

dd {
    margin-bottom: 0.5em;
    margin-left: 0;
}

.getVerifyCode{
    cursor: pointer;
}

.body {
    background: #fbfbfb;

    .page-wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
    }
}
    .ant-table-tbody > tr > td {
        padding: 33px 16px;
        border-bottom: 1px solid #dedede;
    }

    .ant-table-row {
        padding: 32px 0;
    }

    .table-wrap {
        padding: 0 20px;
    }

    .hr {
        height: 20px;
        background: #fbfbfb;
    }

    .container {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        border: 0 solid #ededed;
        background: #fff;
        min-height: 580px;
        padding: 20px 0 0;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    .info-wrap {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        padding: 0 20px;

        .head-info-wrap {
            width: 136px;
            height: 124px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;

            .head {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                overflow: hidden;
            }

            .name {
                color: #333;
                margin: 13px 5px 0;
                line-height: 20px;
                word-break: break-all;
                word-wrap: break-word;
                text-align: center;
            }
        }

        .user-info-wrap {
            width: 100%;
            padding: 20px;

            li {
                line-height: 20px;
                margin-bottom: 10px;
            }
        }
    }

</style>